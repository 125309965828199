/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'scroll-snap-slider';

ion-header {
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.04);

  &.header-collapse-condense,
  &.header-collapse-condense-inactive {
    box-shadow: none;
  }

  ion-toolbar {
    --border-width: 0 !important;
    --background: var(--ion-background-color, #fff);

    &:first-of-type {
      padding-bottom: 6px;
    }

    &.no-padding-bottom {
      padding-bottom: 0;
    }
  }
}

ion-footer>ion-toolbar {
  --border-width: 0 !important;
  --background: var(--ion-background-color, #fff);
  box-shadow: 0px -10px 10px 0px rgba(0, 0, 0, 0.04);

  ion-button {
    @media (min-width: 768px) {
      max-width: calc((100% / 12) * 8);
      margin: auto;
    }
  }
}

ion-header.no-shadow, ion-footer.no-shadow {
  box-shadow: none !important;
  ion-toolbar {
    box-shadow: none !important;
  }
}

@media (min-width: 768px) {
  .max-width-ipad {
    max-width: calc((100% / 12) * 8);
    display: block;
    margin: 0 auto;
  }
}

  @media (min-width: 992px) {
    .max-width-ipad {
      max-width: 800px;
  }
}

@media (min-width: 992px) {
  .max-width-desktop {
    max-width: 825px;
    display: block;
    margin: 0 auto;
  }
}

ion-list-header>ion-label {
  font-size: 1.3rem;
  margin: 20px 0 10px 0;
}

/* hide outline when button is clicked 
  issue: https://github.com/ionic-team/ionic-framework/issues/20632 */
ion-button {

  &:focus {
    outline: none;
  }

  &.secondary {
    --background: rgba(var(--ion-color-primary-rgb), 0.1)
  }

  --border-radius: 8px;
  min-height: 2.8em;
}

/* remove border of last item in list */
ion-list>ion-item:last-of-type,
ion-list>app-post-item:last-of-type>ion-item {
  --border-width: 0 !important;
}

ion-modal {

  ion-list,
  .list-ios,
  ion-item {
    --background: none;
    background: none;
  }

  &.auto-height {
    --height: auto;
  }

  &.transparent {
    --background: unset;
  }

  &.backdrop-blur {
    backdrop-filter: blur(2px);
  }

  &.add-top-padding > .ion-page {
    padding-top: 100px;
  }
}

ion-modal.stack-modal {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

ion-modal.portfolio-details-modal {
  @media only screen and (min-width: 768px) and (min-height: 768px) {
    --width: 600px;
    --height: 800px;
  }
}

ion-modal.zoomable-image-modal {
  --ion-background-color: rgba(0, 0, 0, 0.5) !important;
}

ion-modal.cancel-subscription {
  ion-label {
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--ion-color-medium);
  }

  p {
    margin-top: 0.5em;
  }

  img {
    display: block;
  }

  div.divider {
    height: 1px;
    background: var(--ion-color-light);
    margin: 1em 0;
  }
}

ion-item {
  &.no-background {
    --background: none;
  }

  &.no-min-height {
    --min-height: 0;
  }

  ion-label>p {
    color: var(--ion-color-medium);
  }

  ion-segment {
    transform: scale(0.8);
    transform-origin: right;
    width: 60%;
    float: right;

    ion-segment-button {
      --padding-end: 0;
      --padding-start: 0;
      min-width: auto;
    }
  }
}

/* Swiper: Stop after one slide on CSS Mode */
.swiper-css-mode > swiper-slide {
  scroll-snap-stop: always;
}

/* Swiper: Linear animation */
.linear-animation-slider .swiper-wrapper{
  transition-timing-function: linear;
}

.swiper-fit-content {
  width: fit-content;
}

.post-body {
  box-shadow: 0px 10px 10px 0px rgb(0 0 0 / 4%);
}

div.input {
  border: solid 1px var(--ion-color-light);
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;

  transform: border 0.5s;

  &.error {
    border: solid 1px var(--ion-color-danger);
  }
}

form p.error {
  padding-left: 10px;
  color: var(--ion-color-danger);
  font-size: 14px;
  margin-top: -5px;
  margin-bottom: 20px;
}

/* Swiper: fit-content on slide width */
swiper-container.fit-content > div.swiper-wrapper > div.swiper-slide {
  width: fit-content !important; 
}

/* Post page excerpt */
.post-page--post-excerpt {
  display: inline-block;
  margin: 0 20px;
  font-weight: 500;
  p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.3em;
  }
}

/* Post page content */
.post-page--post-content {
  display: block;
  --padding-bottom: 8px;

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 14px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 10px 0;
    font-weight: 500;
  }

  p {
    font-size: 1.1rem;
    opacity: 0.8;
    margin-bottom: 16px;
    line-height: 1.3em;
  }

  a {
    color: var(--ion-color-text);
    font-weight: 500;
    text-decoration: none;
    border-bottom: 1.5px solid rgba(var(--ion-color-primary-rgb), 0.6);
  }

  img {
    height: auto;
    margin: 10px 0;
  }

  ul {
    padding-inline-start: 16px;
    li {
      padding: 4px 0;
    }
  }

  figure,
  .wp-block-image {
    margin: 0;

    a {
      border: none;
    }
  }

  .bt-italic {
    display: inline;
    font-style: italic;
  }

  .btce-newsblock {
    padding-bottom: 20px;
  }

  .btce-readalso {
    padding: 14px 0 14px 0;

    .btbl-relative {
      position: relative;
    }

    .btbl-relative:nth-child(3) {
      border-radius: 8px;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border : solid 1px var(--ion-color-light);
      display: flex;
      flex-direction: row;
      margin-top: 0.5rem;
    }

    .btbl-py-2.btbl-mt-1.btbl-space-x-2.btbl-px-4 {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 0.5rem 0 0.5rem;
      gap: 10px;

      :first-child {

        &.btbl-w-24.btbl-shrink-0.btbl-rounded.btbl-shadow-sm{
          width: auto;
        }
      }
    }

    .btbl-rounded {
      border-radius: 8px;
    }

    a {
      text-decoration: none;
      border: none;
    }

    .btbl-font-bold{ 
      font-weight: 600;
    }
  
    .btbl-text-yellow-500 {
      color: var(--ion-color-primary);
    }
  
    .btbl-text-xl {
      font-size: 18px;
    }
  
    .btbl-text-sm {
      font-size: 14px;
    }

    .btbl-block {
      display: block;
    }

    .bt-not-prose a span:nth-child(2) {
      font-weight: 600;
      font-size: 16px;
    }

    img {
      aspect-ratio: 1;
      object-fit: cover;
      height: 60pt;
      width: 60pt;
      border-radius: 6px;
    }
  }


  .btce-readalso__wrapper {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    border-top: dashed 1px var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));
    ;
    border-bottom: dashed 1px var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));
    ;

    a {
      text-decoration: none;
      color: var(--ion-color-text);
      border: none;
    }

    .btce-readalso__imgctn {
      float: left;
      margin-right: 10px;
    }

    .btce-readalso__ctn {
      .btce-readalso__postlink--sub {
        display: block;
        color: var(--ion-color-primary);
        font-weight: 500;
      }

      .btce-readalso__postlink--main {
        display: block;
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;
        margin-top: 2px;
      }
    }
  }

  // Inline Affiliate Breaker
  [id*="btce-partner-block"] {
    display: inline-block;
    padding: 14px 0 20px 0;
    margin: 10px 0;
    border-top: dashed 1px var(--ion-item-border-color,
        var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));
    border-bottom: dashed 1px var(--ion-item-border-color,
        var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));

    // Title
    div:nth-child(1) {
      font-size: 1.1rem;
      font-weight: 600;
      line-height: 1.2em;
      margin-bottom: 6px;

      // Links in Title
      a {
        font-size: 1em;
        font-weight: 600;
        pointer-events: none;
        border-bottom: none;
      }
    }

    // Content
    div:nth-child(2) {
      font-size: 0.9rem;
      color: var(--ion-text-color, #000) !important;
      opacity: 0.7;
    }

    // Button
    a {
      display: inline-block;
      margin-top: 6px;
      font-size: 0.9rem;
      font-weight: 500;
    }
  }

  blockquote {
    margin: 0;
  }

  blockquote.wp-block-quote {
    font-style: italic;
    border-left: 2px solid var(--ion-color-primary);
    padding-left: 10px;
    margin: 10px;
  }

  iframe {
    margin: 20px 0;
  }

  .wp-block-button {
    margin-bottom: 20px;
  }

  .twitter-tweet {
    margin: 0;

    iframe {
      border-radius: 13px;
    }
  }

  object.wp-block-file__embed {
    background: white;
    border: solid var(--ion-color-light);
    border-width: 1px;
  }

  div.wp-block-file mark {
    color: var(--ion-color-text);
    background: transparent !important;
    font-weight: 800;
  }
}

.svg-chart svg {
  width: 90%;
}

/* Change picker font size */
.picker-opts,
.picker-opt {
  font-size: 16px !important;
}

.plyr {
  border-radius: 0.125rem;
}

.plyr--fullscreen-fallback .plyr__controls {
  @media (orientation: portrait) {
    padding-bottom: var(--ion-safe-area-bottom, 0) !important;
  }
}

/* Header Tabs */
ion-toolbar.header-tabs {

  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;

  .scroll-container {
    text-align: center;
    margin-bottom: -3px;
    overflow: auto;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .menu-item {
    --padding-start: 14px;
    --padding-end: 14px;
    height: 38px;
    margin: 0;
    box-sizing: border-box;
    font-size: 14px;

    &.active {
      --padding-top: 2px;
      font-weight: 500;
      border-bottom: 2px solid var(--ion-color-primary);
    }
  }

}

canvas.page-canvas,
ion-thumbnail.page-canvas {
  display: block;
  width: 100%;
  max-width: calc(100vw - 36px);
  margin: 16px auto;
  background: rgba(0, 0, 0, 0.15);
  height: auto;
  max-height: calc(100vh - 60px - 120px - 30px - var(--ion-safe-area-bottom) - var(--ion-safe-area-top));
}

#thumbnail-list {

  canvas.thumbnail,
  ion-thumbnail.thumbnail {
    --width: 75px;
    margin-right: 8px;
    width: var(--width);
    height: 100%;

    transition: opacity 0.2s ease-in-out;

    &.active {
      opacity: 1;
    }

    &.inactive {
      opacity: 0.5;
    }

    // needed for padding on sides
    &:first-of-type {
      padding-left: 16px;
      width: calc(var(--width) + 16px);
    }

    &:last-of-type {
      padding-right: 16px;
      width: calc(var(--width) + 16px);
    }
  }
}

/* Loading Controller Download Progress */
.download-progress {
  span {
    font-weight: 400;
  }
}

/* Podcast Player Modal */
#audio-player-modal {

  --height: 100vh;

  ion-header ion-toolbar:first-of-type {
    padding-top: var(--ion-safe-area-top, 0);
  }


  @media only screen and (min-width: 768px) and (min-height: 600px) {
    --width: 400px;
    --height: 800px;
  }
}

// Custom Colors

.ion-color-positive {
  --ion-color-base: var(--ion-color-positive);
  --ion-color-base-rgb: var(--ion-color-positive-rgb);
  --ion-color-contrast: var(--ion-color-positive-contrast);
  --ion-color-contrast-rgb: var(--ion-color-positive-contrast-rgb);
  --ion-color-shade: var(--ion-color-positive-shade);
  --ion-color-tint: var(--ion-color-positive-tint);
}

.ion-color-negative {
  --ion-color-base: var(--ion-color-negative);
  --ion-color-base-rgb: var(--ion-color-negative-rgb);
  --ion-color-contrast: var(--ion-color-negative-contrast);
  --ion-color-contrast-rgb: var(--ion-color-negative-contrast-rgb);
  --ion-color-shade: var(--ion-color-negative-shade);
  --ion-color-tint: var(--ion-color-negative-tint);
}

/* Utils */

// Truncate text to one line 
.line-clamp-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

// Truncate text to two line 
.line-clamp-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

// Truncate text to three line 
.line-clamp-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* 
* Scroll Snap Slider Adjustments
*/
.scroll-snap-slider {
  height: 100%;
  max-height: 100%;
  margin: 0;
  overflow-x: auto; /* Allow horizontal scrolling by default */
  overflow-y: hidden; /* Prevent vertical overflow by default */
  overscroll-behavior-x: contain; /* Prevent scroll chaining horizontally */
  overscroll-behavior-y: none; /* Prevent any vertical scroll by default */
  scroll-snap-type: x mandatory; /* Enable horizontal snap points by default */
  touch-action: pan-x; /* Allow horizontal touch gestures by default */

  &.slider-direction-vertical {
    overflow-x: hidden; /* Hide horizontal overflow */
    overflow-y: auto; /* Allow vertical scrolling */
    scroll-snap-type: y mandatory; /* Enable vertical snap points */
    touch-action: pan-y; /* Allow vertical touch gestures */
    flex-direction: column; /* Align children in a column for vertical scrolling */
  }

  .scroll-snap-slide {
    position: relative;
    display: block;
    width: 100%; /* Full width for vertical scrolling */
    height: 100%; /* Full height for horizontal scrolling */
    max-height: 100%;
    scroll-snap-align: start; /* Snap alignment */
    scroll-snap-stop: always; /* Ensure snapping stops */
    /* Adjust height for vertical direction */
    &.slider-direction-vertical & {
      width: 100%; /* Full width for vertical scrolling */
      height: auto; /* Adjust height based on content for vertical scrolling */
    }
  }
}

/*
* Display the correct illustration in light and dark mode
*/
body.dark .mode-dependent-illustrations > img.light {
  display: none;
}

@media (prefers-color-scheme: dark) {
  .mode-dependent-illustrations > img.light {
    display: none;
  }

  body.light {
    .mode-dependent-illustrations > img.dark {
      display: none;
    }

    .mode-dependent-illustrations > img.light {
      display: block;
    }
  }
}

@media (prefers-color-scheme: light) {
  body:not(.dark) .mode-dependent-illustrations > img.dark {
    display: none;
  }
}


ion-content {
  ion-toolbar {
    ion-title {
      padding-left: 10px;
    }
    p {
      margin: 0;
      padding-left: 11px;
      color: var(--ion-color-medium);
    }
  }
}

ion-list-header {
  font-size: 1.2rem;
  line-height: 3rem;
}

.page-title {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  padding: 60px 16px 40px 16px;

  h1 {
    margin: 0 auto 0 auto;
    font-weight: bold;
    font-size: 1.8rem;
    color: var(--ion-color-dark)
  }

  p {
    margin: 6px auto 0 auto;
    color: var(--ion-color-medium);
  }
}